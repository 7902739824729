const HttpBackend = require('i18next-http-backend/cjs');
const ChainedBackend = require('i18next-chained-backend').default;
const LocalStorageBackend = require('i18next-localstorage-backend').default;

const isBrowser = typeof window !== 'undefined';
const isDev = process.env.NODE_ENV === 'development';
const isProduction = ['production', 'integration'].includes(process.env.ENVK8S);

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  debug: isDev && process.env.DEBUG_I18NEXT === 'true',
  backend: {
    backendOptions: [
      { expirationTime: isDev ? 60 * 1000 : 60 * 60 * 1000 },
      {},
    ], // 1 hour
    backends: isBrowser ? [LocalStorageBackend, HttpBackend] : [],
    cacheHitMode: 'refresh',
  },
  i18n: {
    defaultLocale: 'fr',
    locales: isProduction ? ['fr'] : ['fr', 'en'],
  },
  initImmediate: false,
  // All namespace used by serverSideTranslations should be listed in here
  ns: ['common', 'error', 'qsnPage'],
  partialBundledLanguages: isBrowser,
  react: {
    // used only for the lazy reload
    bindI18n: 'languageChanged loaded',
    useSuspense: false,
  },
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend] : [],
};
